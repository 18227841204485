 import "firebase/compat/storage"
import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/analytics"

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDWWXezV39PsUZr8Upr4WyIsbZpDyE2zQ8",
    authDomain: "easy-application-b13f2.firebaseapp.com",
    databaseURL: "https://easy-application-b13f2-default-rtdb.firebaseio.com",
    projectId: "easy-application-b13f2",
    storageBucket: "easy-application-b13f2.appspot.com",
    messagingSenderId: "524274344333",
    appId: "1:524274344333:web:4f0100e65981e505b85dc4",
    measurementId: "G-MC23NNTF4F"
})



const auth = firebaseApp.auth()
const db = firebaseApp.database()
const analytics = firebaseApp.analytics()
const storage = firebaseApp.storage()

analytics.setAnalyticsCollectionEnabled(true)

export { auth, db, storage }