import React from "react";
import {useParams} from "react-router-dom";
import {category} from "../utils/constants";
import {school} from "../data/schoolsData";
import FeaturedSchool from "../components/FeaturedSchool";
import * as schoolData from "../data/schoolsData";
import Schools from "../components/Schools";
import ApplyNowCard from "../components/ApplyNowCard";
import SEO from "../seo/SEO";

import briefcase from "../img/briefcase.svg"

function SchoolCategoryPage() {
    const {categoryName} = useParams()

    return (
        <div className="page-wrapper">
            <SEO title={`Apply to ${categoryName.toLowerCase()} schools | Easy Applications`}
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://www.easyapplications.web.app" />
            <div className="section hero product-category-hero">
                <div className="container-default w-container">
                    <div className="product-category-hero">
                        <div className="mg-bottom-20px">
                            <a href="/schools" className="back---link w-inline-block">
                                <div className="line-rounded-icon"></div>
                                <div className="text-200 bold">Back to all schools</div>
                            </a>
                        </div>
                        <div className="mg-bottom-32px">
                            <div className="flex-horizontal">
                                <div className="inner-container _444px">
                                    <div className="flex-vertical center text-center">
                                        <div className="mg-bottom-24px">
                                            <img src={briefcase} alt="Business" className="_w-h-54px"/>
                                        </div>
                                        <div className="display-flex center-mbp">
                                            <h1 className="display-1 mg-bottom-0" style={{ textTransform: "capitalize" }}>
                                                {categoryName}
                                            </h1>
                                        </div>
                                        <p className="mg-bottom-0 paragraph-small">
                                            {category.filter((search) => search.url === categoryName).map((item) => (
                                                <div>{item.description}</div>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mg-bottom-64px">
                            <div className="inner-container _600px center">
                                {/*<div className="home-products-filter">*/}
                                {/*    <form action="/search" className="mg-bottom-0 width-100 w-form">*/}
                                {/*        <div className="position-relative">*/}
                                {/*            <input type="search" className="input small search-btn-inside w-input" maxLength="256"*/}
                                {/*                   name="query" placeholder="Search for..." id="search" required=""/>*/}
                                {/*            <div className="btn-inside-input-wrapper">*/}
                                {/*                <input type="submit" value="Search" className="btn-primary small w-button"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </form>*/}
                                {/*    <div className="products-filters-dropdown-wrapper">*/}
                                {/*        <div data-hover="true" data-delay="0" className="card products-filters-dropdown w-dropdown">*/}
                                {/*            <div className="products-filters-dropdown-button w-dropdown-toggle">*/}
                                {/*                <div className="flex-horizontal start gap-4px">*/}
                                {/*                    <img*/}
                                {/*                        src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63bf03d922d30f693222d83e_slider-horizontal-aggregator-x-webflow-template.svg"*/}
                                {/*                        alt="" className="_w-h-20px"/>*/}
                                {/*                    <div className="text-100 medium color-neutral-600">Filter by category</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="line-rounded-icon arrow-down"></div>*/}
                                {/*            </div>*/}
                                {/*            <nav className="products-filters-dropdown-list category w-dropdown-list">*/}
                                {/*                <div className="card product-categories-wrapper">*/}
                                {/*                    <div className="categories-card-wrapper v1">*/}
                                {/*                        <div className="w-dyn-list">*/}
                                {/*                            <div role="list"*/}
                                {/*                                 className="grid-1-column categories-grid w-dyn-items">*/}
                                {/*                                <div role="listitem" className="w-dyn-item">*/}
                                {/*                                    <a data-w-id="03edbaf6-b452-7404-3178-7b93abe6cc33"*/}
                                {/*                                       href="/product-categories/business"*/}
                                {/*                                       aria-current="page"*/}
                                {/*                                       className="category---link-wrapper w-inline-block w--current">*/}
                                {/*                                        <div className="category---description-wrapper">*/}
                                {/*                                            <div>*/}
                                {/*                                                <img*/}
                                {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c57f0a578243231624ebd4_briefcase-aggregator-x-webflow-template.svg"*/}
                                {/*                                                    alt="Business"*/}
                                {/*                                                    className="_w-h-20px"/>*/}
                                {/*                                            </div>*/}
                                {/*                                            <div className="category---description">*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---title">Business*/}
                                {/*                                                </div>*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---short-description">Massa*/}
                                {/*                                                    sit praesent leo morbi*/}
                                {/*                                                </div>*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                        <div className="categories---arrow-right">*/}
                                {/*                                            <div*/}
                                {/*                                                className="line-rounded-icon link-icon-right">*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                    </a>*/}
                                {/*                                </div>*/}
                                {/*                                <div role="listitem" className="w-dyn-item">*/}
                                {/*                                    <a data-w-id="03edbaf6-b452-7404-3178-7b93abe6cc33"*/}
                                {/*                                       href="/product-categories/design"*/}
                                {/*                                       className="category---link-wrapper w-inline-block">*/}
                                {/*                                        <div className="category---description-wrapper">*/}
                                {/*                                            <div>*/}
                                {/*                                                <img*/}
                                {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c57eeec8b8535877b91cd0_pencil-aggregator-x-webflow-template.svg"*/}
                                {/*                                                    alt="Design" className="_w-h-20px"/>*/}
                                {/*                                            </div>*/}
                                {/*                                            <div className="category---description">*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---title">Design*/}
                                {/*                                                </div>*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---short-description">Donec*/}
                                {/*                                                    aliquet urna eu*/}
                                {/*                                                </div>*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                        <div className="categories---arrow-right">*/}
                                {/*                                            <div*/}
                                {/*                                                className="line-rounded-icon link-icon-right">*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                    </a>*/}
                                {/*                                </div>*/}
                                {/*                                <div role="listitem" className="w-dyn-item">*/}
                                {/*                                    <a data-w-id="03edbaf6-b452-7404-3178-7b93abe6cc33"*/}
                                {/*                                       href="/product-categories/development"*/}
                                {/*                                       className="category---link-wrapper w-inline-block">*/}
                                {/*                                        <div className="category---description-wrapper">*/}
                                {/*                                            <div>*/}
                                {/*                                                <img*/}
                                {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c57ec7f0ee7312b9f4e65a_monitor-aggregator-x-webflow-template.svg"*/}
                                {/*                                                    alt="Development"*/}
                                {/*                                                    className="_w-h-20px"/>*/}
                                {/*                                            </div>*/}
                                {/*                                            <div className="category---description">*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---title">Development*/}
                                {/*                                                </div>*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---short-description">Suspendisse*/}
                                {/*                                                    et non et dolor*/}
                                {/*                                                </div>*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                        <div className="categories---arrow-right">*/}
                                {/*                                            <div*/}
                                {/*                                                className="line-rounded-icon link-icon-right">*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                    </a>*/}
                                {/*                                </div>*/}
                                {/*                                <div role="listitem" className="w-dyn-item">*/}
                                {/*                                    <a data-w-id="03edbaf6-b452-7404-3178-7b93abe6cc33"*/}
                                {/*                                       href="/product-categories/marketing"*/}
                                {/*                                       className="category---link-wrapper w-inline-block">*/}
                                {/*                                        <div className="category---description-wrapper">*/}
                                {/*                                            <div>*/}
                                {/*                                                <img*/}
                                {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c57e995c1979ddb6479977_pencil-simple-aggregator-x-webflow-template.svg"*/}
                                {/*                                                    alt="Marketing"*/}
                                {/*                                                    className="_w-h-20px"/>*/}
                                {/*                                            </div>*/}
                                {/*                                            <div className="category---description">*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---title">Marketing*/}
                                {/*                                                </div>*/}
                                {/*                                                <div*/}
                                {/*                                                    className="category---short-description">Id*/}
                                {/*                                                    morbi eget velit*/}
                                {/*                                                </div>*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                        <div className="categories---arrow-right">*/}
                                {/*                                            <div*/}
                                {/*                                                className="line-rounded-icon link-icon-right">*/}
                                {/*                                            </div>*/}
                                {/*                                        </div>*/}
                                {/*                                    </a>*/}
                                {/*                                </div>*/}
                                {/*                            </div>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </nav>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="product-board-wrapper">
                        <div className="split-content product-board-content">
                            <div className="featured-products-wrapper">
                                <div className="mg-bottom-32px mg-bottom-40px---mbp">
                                    <div className="display-flex">
                                        <div className="display-3">Featured</div>
                                        <h2 className="display-3 mg-bottom-0">{categoryName}</h2>
                                        <div className="display-3">schools</div>
                                    </div>
                                </div>
                                <div className="w-dyn-list">
                                    <div role="list" className="grid-1-column product-grid-v1 w-dyn-items">
                                        {school.filter((search) => search.schoolPhase.toLowerCase() === categoryName).map((item) => (
                                            <FeaturedSchool schoolName={item.name} physicalAddress={item.physical_address} schoolPhase={item.type} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="products-wrapper">*/}
                            {/*    <div className="mg-bottom-32px">*/}
                            {/*        <div className="flex-title">*/}
                            {/*            <div className="display-flex">*/}
                            {/*                <div className="display-3">All</div>*/}
                            {/*                <h2 className="display-3 mg-bottom-0">{categoryName}</h2>*/}
                            {/*                <div className="display-3">schools</div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="w-dyn-list">*/}
                            {/*        <div role="list" className="grid-1-column product-grid-v1 w-dyn-items">*/}
                            {/*            {schoolData.school.filter((search) => search.schoolPhase === categoryName).map((item) => (*/}
                            {/*                <Schools schoolName={item.schoolName} physicalAddress={item.physical_address} schoolPhase={item.type} />*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="split-content product-sidebar">
                            <div className="position-sticky top-24px static---tablet">
                                <ApplyNowCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="section bg-neutral-200 overflow-hidden">*/}
            {/*    <div className="container-default w-container">*/}
            {/*        <div>*/}
            {/*            <div data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb2"*/}
            {/*                 className="flex-horizontal mg-bottom-40px">*/}
            {/*                <h2 className="display-3 mg-bottom-0">Collections curated by our team</h2>*/}
            {/*            </div>*/}
            {/*            <div data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb5"*/}
            {/*                 className="w-layout-grid grid-3-columns _1-col-tablet">*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb6"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/development"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            alt="Apps For Developers - Aggregator X Webflow Template"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for developers</h3>*/}
            {/*                        <p className="paragraph-small mg-bottom-20px">Lorem ipsum dolor sit amet consectetur*/}
            {/*                            adipiscing elit duis blandit viverra</p>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386ec4"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/marketing"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            alt="Apps For Marketers - Aggregator X Webflow Template"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for marketers</h3>*/}
            {/*                        <p className="paragraph-small mg-bottom-20px">Id sit adipiscing amet amet sem*/}
            {/*                            volutpat turpis posuere turpis curabitur parturient.</p>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386ed2"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/design"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            alt="Apps For Designers - Aggregator X Webflow Template"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for designers</h3>*/}
            {/*                        <div className="inner-container _280px _100-tablet">*/}
            {/*                            <p className="paragraph-small mg-bottom-20px">Tempus a sit convallis amet*/}
            {/*                                ultrices eget eleifend tincidunt cras ultrices egestas.</p>*/}
            {/*                        </div>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default SchoolCategoryPage