import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import SchoolsPage from "./pages/SchoolsPage";
import ContactPage from "./pages/ContactPage";
import SchoolCategoryPage from "./pages/SchoolCategoryPage";
import SEO from "./seo/SEO";
import Hero from "./components/Hero";
import React from "react";
import BecomeMemberPage from "./pages/BecomeMemberPage";
import ComingSoonPage from "./pages/ComingSoonPage";

function App() {
  return (
      <BrowserRouter>
          <SEO title="Apply to schools with Easy Applications | Your future starts here"
               description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
               canonicalURL="https://www.easyapplications.web.app" />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/school-directory" element={<SchoolsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/for-schools" element={<BecomeMemberPage />} />
          <Route path="/schools/:categoryName" element={<SchoolCategoryPage />} />
          <Route path="/whatsapp-ai-chatbot" element={<ComingSoonPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
  );
}

export default App;
