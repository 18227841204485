import React from "react";
import {category, navLinks} from "../utils/constants";
import Logo from "./Logo";

import briefcase from "../img/briefcase.svg"
import star from "../img/star.svg"

function Footer() {

    return (
        <footer className="bg-neutral-200">
            <div className="container-default w-container">
                <div className="footer-top-v1">
                    <div className="mg-bottom-80px">
                        <div className="w-layout-grid grid-4-columns footer-subgrid-main">
                            <div className="inner-container _252px _100-tablet">
                                <a href="/" className="footer-logo-wrapper w-inline-block">
                                    <Logo />
                                </a>
                                <p className="mg-bottom-24px">
                                    Applying to Creches, Primary and High Schools made easier.
                                </p>
                                <div className="social-media-flex">
                                    <a href="https://facebook.com/" className="social-icon-square w-inline-block">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://twitter.com/" className="social-icon-square w-inline-block">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.instagram.com/" className="social-icon-square w-inline-block">
                                        <div className="social-icon-font"></div>
                                    </a>
                                    <a href="https://www.linkedin.com/" className="social-icon-square w-inline-block">
                                        <div className="social-icon-font"></div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="text-300 footer-column-title">Main pages</div>
                                <div className="dropdown-subgrid footer-dropdown-subgrid">
                                    <div className="w-layout-grid footer-subgrid" style={{ display: "flex" }}>
                                        {navLinks.map((item) => (
                                            <a href={item.url} className="footer-link-v1">
                                                {item.title}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="text-300 footer-column-title">Categories</div>
                                <div className="categories-card-wrapper v1">
                                    <div className="w-dyn-list">
                                        <div role="list" className="grid-1-column gap-row-24px w-dyn-items">
                                            {category.map((item) => (
                                                <div role="listitem" className="w-dyn-item">
                                                    <a href={`schools/${item.url}`} className="category-footer-link w-inline-block">
                                                        <div className="category---description-wrapper">
                                                            <div>
                                                                <img src={briefcase} alt="Business" className="_w-h-20px"/>
                                                            </div>
                                                            <div className="category---description">
                                                                <div className="text-100 bold">{item.name}</div>
                                                                <div className="category---short-description">
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-layout-grid grid-2-columns">
                    <div className="footer-card">
                        <div className="footer-card-wrapper">
                            <div className="flex-horizontal start flex-vertical---mbp">
                                <div className="circle _48px blue">
                                    <div className="line-rounded-icon arrow-up"></div>
                                </div>
                                <div className="mg-left-12px mg-left-0-mbp">
                                    <div className="heading-h5-size">Start your child's application!</div>
                                    <div className="paragraph-small">Apply to schools of choice easily and quickly.</div>
                                </div>
                            </div>
                            <div className="width-100-mbp">
                                <a href="https://apply.easyapplications.co.za" className="btn-primary small w-button">
                                    Start application
                                    <span className="line-rounded-icon link-icon-right mg-left-4px"></span>
                                </a>
                            </div>
                        </div>
                        <div className="floating-item circle-floating"></div>
                    </div>
                    <div className="footer-card blue">
                        <div className="footer-card-wrapper">
                            <div className="flex-horizontal start flex-vertical---mbp">
                                <div className="circle _48px white">
                                    <img src={star} alt=""/>
                                </div>
                                <div className="mg-left-12px mg-left-0-mbp">
                                    <div className="heading-h5-size color-neutral-100">List your school on our directory!
                                    </div>
                                    <div className="paragraph-small color-neutral-300">
                                        Receive applications through EASY Applications
                                    </div>
                                </div>
                            </div>
                            <div className="width-100-mbp">
                                <a href="https://school.easyapplications.co.za" className="btn-primary small black w-button">
                                    Join now
                                    <span className="line-rounded-icon link-icon-right mg-left-4px"></span>
                                </a>
                            </div>
                        </div>
                        <div className="floating-item circle-floating white"></div>
                    </div>
                </div>
                <div className="footer-bottom-v1">
                    <div className="footer-bottom-flex">
                        <p className="mg-bottom-0 text-center">
                            Copyright © EASY Applications (Pty) Ltd | Designed and Powered by
                            <a href="https://only-dev.co.za/" target="_blank" className="footer-credits"> OnlyDev</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer