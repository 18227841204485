import React from "react";
import {school} from "../data/schoolsData";
import FeaturedSchool from "./FeaturedSchool";
import schoolData from "../data/private_college.json";
import Schools from "./Schools";
import {category} from "../utils/constants";
import ApplyNowCard from "./ApplyNowCard";

import curvyLine from "../img/curvy-line.svg"
import chat from "../img/chat.svg"
import barchart from "../img/barchar.svg"
import rhombus from "../img/rhombus.svg"
import smallBlackCircle from "../img/small-black-circle.svg"
import smallCurvyLine from "../img/small-curvy-line.svg"
import smallBlueCircle from "../img/small-blue-circle.svg"
import carpet from "../img/carpet.svg"
import triangle from "../img/triangle.svg"
import window from "../img/window.svg"
import briefcase from "../img/briefcase.svg"

function Hero() {
    const showCategory = () => {

    }

    return (
        <div className="section hero home-hero">
            <div className="container-default w-container">
                <div className="mg-bottom-64px">
                    <div className="top-section-card v1">
                        <div className="w-layout-grid grid-3-columns home-grid">
                            <div className="position-relative">
                                <img src={curvyLine} alt="" className="floating-item curvy-line-floating v2"/>
                                <img src={chat} alt="" className="floating-item popup-floating"/>
                                <img src={barchart} alt="" className="floating-item chart-floating"/>
                                <img src={rhombus} alt="" className="floating-item rhombus-floating v1"/>
                                <img src={smallBlackCircle} alt="" className="floating-item shape-v19"/>
                            </div>
                            <div className="flex-vertical center text-center">
                                <div className="inner-container _424px">
                                    <h1 className="display-2 mg-bottom-12px z-index-1">
                                        Apply to Creches, Primary and High Schools.
                                    </h1>
                                </div>
                                <div className="inner-container _444px">
                                    <p className="paragraph-small mg-bottom-20px">
                                        Whether you're applying to pre-school, primary and high school for the first or
                                        transferring to complete your educational journey, here's how to get started on
                                        your application.
                                    </p>
                                </div>
                                <div className="home-products-filter v1">
                                    <div className="mg-bottom-0 search-v1 w-form">
                                        <div className="position-relative">
                                            <a href="https://apply.easyapplications.co.za" className="w-button medium btn-primary">
                                                Go to Student Portal
                                            </a>
                                        </div>
                                    </div>
                                    <div className="products-filters-dropdown-wrapper">
                                        <div data-hover="false" data-delay="0" className="card products-filters-dropdown bg-neutral-100 width-100-mbl w-dropdown">
                                            <div className="products-filters-dropdown-button w-dropdown-toggle">
                                                <div className="flex-horizontal start gap-4px">
                                                    <a href="https://school.easyapplications.co.za" className="medium color-neutral-600">
                                                        Go to School Portal
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <img src={smallCurvyLine} alt="" className="floating-item curvy-line-floating v1"/>
                                <img src={smallBlueCircle} alt="" className="floating-item shape-v18"/>
                                <img src={carpet} alt="" className="floating-item carpet-floating"/>
                                <img src={triangle} alt="" className="floating-item triangle-floating v1"/>
                                <img src={window} alt="" className="floating-item window-floating"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-board-wrapper">
                    <div className="split-content product-board-content">
                        {/*<div className="featured-products-wrapper">*/}
                        {/*    <div className="mg-bottom-32px">*/}
                        {/*        <h2 className="display-3 mg-bottom-0">Featured schools</h2>*/}
                        {/*    </div>*/}
                        {/*    <div className="w-dyn-list">*/}
                        {/*        <div role="list" className="grid-1-column product-grid-v1 w-dyn-items">*/}
                        {/*            {school.map((item) => (*/}
                        {/*                <div>*/}
                        {/*                    <FeaturedSchool schoolName={item.name}*/}
                        {/*                                    physicalAddress={item.physical_address}*/}
                        {/*                                    schoolPhase={item.type} />*/}
                        {/*                </div>*/}
                        {/*            ))}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="products-wrapper">
                            <div className="mg-bottom-32px mg-bottom-40px---mbp">
                                <div className="flex-title">
                                    <h2 className="display-3 mg-bottom-0">Latest schools</h2>
                                    <a href="/schools" className="btn-secondary small w-button">
                                        Browse all schools
                                    </a>
                                </div>
                            </div>
                            <div className="w-dyn-list">
                                <div role="list" className="grid-1-column product-grid-v1 w-dyn-items">
                                    {schoolData.school.splice(0, 6).map((item) => (
                                        <Schools schoolName={item.name}
                                                 physicalAddress={item.physical_address}
                                                 schoolPhase={item.type} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="split-content product-sidebar">
                        <div className="position-sticky top-24px static---tablet">
                            <h3 className="heading-h5-size mg-bottom-20px">Filter by category</h3>
                            <div className="mg-bottom-32px">
                                <div className="inner-container _312px _100-tablet">
                                    <div className="categories-card-wrapper">
                                        <div className="w-dyn-list">
                                            <div role="list" className="grid-1-column categories-grid w-dyn-items">
                                                {category.map((item) => (
                                                    <div role="listitem" className="w-dyn-item">
                                                        <a href={`/schools/${item.url}`} className="category---link-wrapper w-inline-block">
                                                            <div className="category---description-wrapper">
                                                                <div>
                                                                    <img src={briefcase} alt="Business" className="_w-h-20px"/>
                                                                </div>
                                                                <div className="category---description">
                                                                    <div className="category---title">{item.name}</div>
                                                                    <div className="category---short-description">
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="categories---arrow-right">
                                                                <div className="line-rounded-icon link-icon-right"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ApplyNowCard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero