import React, {useState} from "react";
import Logo from "./Logo";
import {NavLink} from "react-router-dom";
import {navLinks} from "../utils/constants";

function Header() {
    const [navOpen, setNavOpen] = useState(false)

    const handleNavOpen = () => {
        if (navOpen) {
            setNavOpen(false)
        } else {
            setNavOpen(true)
        }
    }

    return (
        <div data-collapse="medium" className="header-wrapper w-nav">
            <div className="container-default w-container">
                <div className="header-content-wrapper">
                    <div className="header-left-side flex-growth-tablet">
                        <Logo />
                        <nav role="navigation" className="header-nav-menu-wrapper w-nav-menu">
                            <ul role="list" className="header-nav-menu-list">
                                {navLinks.map((item) => (
                                    <NavLink to={item.url}>
                                        {({ isActive }) => {
                                            if (isActive) {
                                                return (
                                                    <li className="header-nav-list-item left w--current">
                                                        <div className="header-nav-link w-nav-link" style={{ color: '#3898ec' }}>
                                                            {item.title}
                                                        </div>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li className="header-nav-list-item left">
                                                        <div className="header-nav-link w-nav-link">{item.title}</div>
                                                    </li>
                                                )
                                            }
                                        }}
                                        </NavLink>
                                ))}
                                <li className="header-nav-list-item show-in-tablet">
                                    <a href="https://apply.easyapplications.co.za" className="btn-secondary small w-button">
                                        Go to Student Portal
                                    </a>
                                </li>
                                <li className="header-nav-list-item show-in-tablet">
                                    <a href="https://school.easyapplications.co.za" className="btn-primary small w-button">
                                        <span className="filled-icon-star"> </span>
                                        Go to School Portal
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right-side">
                        <a href="https://apply.easyapplications.co.za" className="btn-secondary small header-btn-hidden-on-tablet w-button">
                            Go to Student Portal
                        </a>
                        <a href="https://school.easyapplications.co.za" className="btn-primary small header-btn-hidden-on-tablet w-button">
                            <span className="filled-icon-star"> </span>
                            Go to School Portal
                        </a>
                        <div className="hamburger-menu-wrapper w-nav-button" onClick={handleNavOpen}>
                            <div className="hamburger-menu-bar top" style={{ transform: `translate3d(0px, ${navOpen ? "8px" : "0px"}, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) ${navOpen ? "rotateZ(135deg)" : "rotateZ(0deg)"} skew(0deg, 0deg)`,
                                transformStyle: "preserve-3d", backgroundColor: `rgb(12, 24, 48)` }}></div>
                            <div className="hamburger-menu-bar bottom" style={{ transform: `translate3d(0px, ${navOpen ? "-7px" : "0px"}, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) ${navOpen ? "rotateZ(45deg)" : "rotateZ(0deg)"} skew(0deg, 0deg)`,
                                transformStyle: "preserve-3d", backgroundColor: `rgb(12, 24, 48)` }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: navOpen ? "block" : "none"}}>
                <nav role="navigation" className="header-nav-menu-wrapper w-nav-menu" data-nav-menu-open=""
                     style={{maxWidth: "1268px", transform: `translateY(0px)`, transition: `transform 400ms ease 0s`}}>
                    <ul role="list" className="header-nav-menu-list">
                        {navLinks.map((item) => (
                            <NavLink to={item.url}>
                                {({ isActive }) => {
                                    if (isActive) {
                                        return (
                                            <li className="header-nav-list-item left">
                                                <a href="/home" aria-current="page" className="header-nav-link w-nav-link w--current w--nav-link-open"
                                                   style={{maxWidth: "1268px"}}>{item.title}</a>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li className="header-nav-list-item left">
                                                <a href="/products" className="header-nav-link w-nav-link w--nav-link-open"
                                                   style={{maxWidth: "1268px"}}>{item.title}</a>
                                            </li>
                                        )
                                    }
                                }}
                            </NavLink>
                        ))}
                        <li className="header-nav-list-item show-in-tablet">
                            <a href="https://apply.easyapplications.co.za" className="btn-secondary small w-button">
                                Go to Student Portal
                            </a>
                        </li>
                        <li className="header-nav-list-item show-in-tablet">
                            <a href="https://school.easyapplications.co.za" className="btn-primary small w-button">
                                <span className="filled-icon-star"> </span>
                                Go to School Portal
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header