import React from "react";
import imgCheck from "../img/ic-check.svg"

function BecomeMemberPage() {
    return (
        <div  className="page-wrapper">
            <div className="section hero credit-single-hero border-bottom---bg-neutral-300">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-2-columns credit-grid-2-cols v1">
                        <div className="card pd-48px---40px-content-center">
                            <div className="inner-container _624px">
                                <div className="w-layout-grid grid-1-column">
                                    <div>
                                        <h1 className="display-2 mg-bottom-8px">List your school</h1>
                                        <div className="inner-container">
                                            <p className="paragraph-small mg-bottom-0">
                                                EASY Applications Directory is an all-new database of private schools in South Africa.
                                                Easy to use by parents, it allows them to search for schools by location with a
                                                number of search criteria. Create your free account today so parents can find your school!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider _40px"></div>
                                <h2 className="heading-h3-size">About the plan</h2>
                                <div className="rich-text w-richtext">
                                    <p>
                                        Schools will receive all features of the EASY software, plus enquiries from the
                                        directory will be automatically synced with their EASY Applications account, creating one
                                        streamlined workflow for enquiry generation, application management and student enrolment.
                                    </p>
                                    <ul role="list">
                                        <li>Searchable and Featured School Listing</li>
                                        <li>View enquired student and parent information</li>
                                        <li>Re-Enrolment management and Online Applicant Review</li>
                                        <li>Online application form, admissions reporting and analytics</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="position-sticky top-24px static---tablet">
                            <div className="card pd-54px---40px-content-center">
                                <div className="inner-container _274px _100-tablet">
                                    <div className="display-4 mg-bottom-8px">Get started for</div>
                                    <p className="paragraph-small">
                                        All EASY Applications features included
                                    </p>
                                    <h3 className="display-3 mg-bottom-16px">R 499.99 p/mo</h3>
                                    <div>
                                        <a className="w-commerce-commercebuynowbutton btn-secondary mg-top-0 width-100" href="https://school.easyapplications.co.za">
                                            Get Started
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BecomeMemberPage